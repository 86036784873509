import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { ApiService } from '../../services/api.service';
import { ToasterService } from '../../services/toaster.service';
import { ApiConstant } from '../../constants/api-constants';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { authService } from '../../services/auth.service';
import { slideInParent } from '../../animations/animations.component';
import { EditProfileComponent } from '../../../feature/account/edit-profile/edit-profile.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    EditProfileComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [slideInParent]
})
export class HeaderComponent implements OnInit {
  private unSubscribe = new Subject<void>();
  showProfile = false;
  userProfile: any;

  openUserEdit:boolean=false;
  editType!:string;
  userDetails:any;
  thinkBioUrl!:SafeUrl
  constructor(
    private loaderService: LoaderService,
    private apiService: ApiService,
    private toasterService: ToasterService,
    private router: Router,
    private authService: authService,
    private _elementRef: ElementRef,
    private sanitizer:DomSanitizer
  ) {
    this.thinkBioUrl=this.sanitizer.bypassSecurityTrustUrl("https://thinkbio.ai/");
  }

  ngOnInit(): void {
    this.userProfile = this.authService.getUserInfo();
  }
  @HostListener('document:click', ['$event'])
   clickout(event: any) {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.showProfile = false;
    }
  }
  
  public logout() {
    const endpoint = this.apiService.getApiUrl(ApiConstant.AUTHENTICATION.LOGOUT);
    this.loaderService.displayLoader(true);
    this.apiService
      .ExecutePost(endpoint, {})
      .pipe(takeUntil(this.unSubscribe))
      .subscribe({
        next: (response: any) => {
          this.loaderService.displayLoader(false);
          if (response.success === true) {
            this.toasterService.alert.toast({
              type: 'success',
              message: response.message,
            });
            this.authService.deleteUserInfo();
            this.router.navigateByUrl('/login');
          } else {
            this.toasterService.alert.toast({
              type: 'error',
              message: response.message,
            });
          }
        },
        error: (error: any) => {
          this.loaderService.displayLoader(false);
          this.toasterService.alert.toast({
            type: 'error',
            message: error.message,
          });
        },
      });
  }

 
  editUser(type:string){
    this.editType=type;
    if(type=='edit-profile'){
      this.getUserDetails();
    }else{
      this.showProfile = false;
      this.openUserEdit=true;
    }
  }

  getUserDetails() {
    const user_id=this.userProfile?.user_id;
    const endpoint = this.apiService.getApiUrl(ApiConstant.SETTINGS.USER.ENDPOINT + `/${user_id}`);
    this.loaderService.displayLoader(true);
    this.apiService.ExecuteGet(endpoint)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe({
        next: (response: any) => {
          this.loaderService.displayLoader(false);
          if (response?.success) {
           this.userDetails=response?.data?.records[0];
           this.showProfile = false;
           this.openUserEdit=true;
          } else {
            this.toasterService.alert.toast({
              type: 'error',
              message: response.error,
            });
          }
        },
        error: (error: any) => {
          this.loaderService.displayLoader(false);
          this.toasterService.alert.toast({
            type: 'error',
            message: error.error,
          });
        },
      });
  }
  editClosed(){
    this.userProfile = this.authService.getUserInfo();
    this.openUserEdit=false;
  }
}
