@if (toaster) {
  <ngb-toast
    #ngbToast
    class="alert  {{ alertClass[toaster.type] }}"
    [header]="toaster.title || ''"
    [autohide]="toaster.autohide || true"
    [delay]="toaster.delay || 5000"
    [animation]="true"
    (hidden)="closeToaster()"
  >
    <div class="toaster-body" [tabIndex]="1">
      {{ toaster.message || "Something bad happened; please try again later" }}
      @if (!toaster.title) {
        <span class="btn-close float-end" (click)="ngbToast.hide()"></span>
      }
    </div>
  </ngb-toast>
}
