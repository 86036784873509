<aside>
  <div class="sidebar">
    <ul class="main-menu">
      <li>
        <a class="dashboard"  routerLinkActive="active" (click)="routeChange(false)"> Dashboard</a>
      </li>
      @if(hasPermission('inventory')){
      @for (item1 of menu; track $index) {
      <li>
        <a class="inventory" (click)="onClick(item1)" [ngClass]="{'active': isInventory}"> {{item1.value}}</a>
        @if (item1.child?.length) {
        <ul class="sub-menu">
          @for (item2 of item1.child; track item2; let i = $index) {
          <li [ngClass]="{'sub-list': item2.child?.length, 'open': isOpen[0][i]}">
            <a (click)="onClick(item2,item1); toggleOpen(i, 0)" routerLinkActive="active">
              {{item2.value}}</a>
            @if (item2.child?.length) {
            <ul [ngClass]="{'menu-show': isOpen[0][i]}" class="sub-sub-menu">
              @for (item3 of item2.child; track item3; let j = $index) {
              <li [ngClass]="{'inner-list': item3.child?.length, 'disabled-menu': item3.route === 'patient_sample','open-sub': isOpen[1][j] }"><a
                  (click)="onClick(item3,item2,item1); toggleOpen(j, 1)" routerLinkActive="active">{{item3.value}}</a>
                @if (item3.child?.length){
                <ul [ngClass]="{'menu-show': isOpen[1][j]}" class="inner-menu ">
                  @for (item4 of item3.child; track $index) {
                  <li> <a (click)="onClick(item4,item3,item2,item1)" routerLinkActive="active">{{item4.value}}</a>
                  </li>
                  }
                </ul>
                }
              </li>
              }
            </ul>
            }
          </li>
          }
        </ul>
        }
      </li>
      }
      }
      @if(hasPermission('protocols')){
      <li>
        <a routerLink="/protocols" class="protocols" routerLinkActive="active" (click)="routeChange(false)"> Protocols</a>
      </li>
      }
      @if(hasPermission('projects')){
      <li>
        <a class="project" routerLink="/projects" routerLinkActive="active" (click)="routeChange(false)"> Projects</a>
      </li>
      }
      @if(hasPermission('products')){
      <li>
        <a class="product" routerLink="/products" routerLinkActive="active"> Products</a>
      </li>
      }
      @if(hasPermission('vendors')){
      <li>
        <a routerLink="/vendors" class="vendors" routerLinkActive="active" (click)="routeChange(false)"> Vendors</a>
      </li>
      }
      <li>
        <a class="search-menu"  routerLinkActive="active" (click)="routeChange(false)"> Search</a>
      </li>
      @if(hasPermission('settings')){
      <li>
        <a class="settings" routerLink="/settings/user" routerLinkActive="active" (click)="routeChange(false)"> Settings</a>
      </li>
      }
    </ul>
  </div>
</aside>