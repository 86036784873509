@if (show) {
  <div
    class="modal confirm-modal fade show"
    id="confirm-modal-warning"
    tabindex="-1"
    aria-labelledby="confirmation-modal"
    aria-modal="true"
    role="dialog"
    style="display: block; padding-left: 0px"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close('yes')"></button>
        <div class="modal-body">
          <div class="confirm-modal-body">
            <div class="warning-icon"></div>
            <h2 id="confirmation-modal">{{title}}</h2>
            <p>{{text}}</p>
            <div class="confirm-buttons">
              <button class="btn btn-outline-primary w-100" (click)="submit('no')" id="confirmation-no-btn">No</button>
                  <button class="btn btn-primary w-100 ms-2" (click)="submit('yes')" id="confirmation-no-btn">Yes</button>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

