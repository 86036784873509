import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Menu } from '../../interface/menu';
import { CommonModule } from '@angular/common';
import { authService } from '../../services/auth.service';
import { APP_CONSTANTS } from '../../constants/app-constants';
import { EnvironmentService } from '../../../../environments/environment.service';
@Component({
    selector: 'app-side-menu',
    imports: [RouterModule, CommonModule],
    templateUrl: './side-menu.component.html',
    styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent implements OnInit {
  @Input() menu: Menu[] = [];
  menu_items: string[] = [];
  isOpen: boolean[][] = [[], []];
  isInventory = false;

  constructor(
    private router: Router,
    private authService: authService,
    public environmentService: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.menu_items = this.authService.getUserInfo()?.menu_item;
    this.isInventory = this.router.url.split('/')[1] == 'inventory' ? true : false;
  }

  toggleOpen(index: number, level: number): void {
    if (!this.isOpen[level]) {
      this.isOpen[level] = [];
    }
    this.isOpen[level][index] = !this.isOpen[level][index];
  }

  onClick(...menus: Menu[]) {
    if (menus.length && !menus[0].child?.length) {
      let route: string[] = [];
      menus.forEach((m) => {
        route.splice(0, 0, m.route);
      });
      if (route[route.length - 1] === 'patient_sample') {
        return;
      } else {
        this.router.navigate(route);
        this.isInventory = route[0] == 'inventory' ? true : false;
      }
    }
  }
  routeChange(flag: boolean) {
    this.isInventory = flag;
  }
  hasPermission(menuItem: string): boolean {
    return this.menu_items?.includes(menuItem);
  }
}
