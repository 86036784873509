<header class="header">
  <div class="logo-wrap">
    <a class="logo">   <img src="assets/images/logos/logo-black.svg" alt=" Lims Logo" /></a>
    <p>Powered by</p>
    <a class="thinkbio-logo" [href]="thinkBioUrl" target="_blank">   <img src="assets/images/logos/thinkbio-logo.svg" alt="Thinkbio Logo" /></a>
  </div>
  <div class="d-flex">
    <div
      class="user-wrap"
      href="#"
      id="navbarDarkDropdownMenuLink"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false" aria-label="Show profile" (click)="showProfile = !showProfile"
    >
      <div  class="user-img">
        <img src="assets/images/icons/user-icon.svg" alt="" />
      </div>
    </div>
    @if(showProfile){
      <div class="dropdown-menu slide-up-animation d-block user-dropdown">
        <div class="user">
          <img src="assets/images/icons/user-icon.svg" alt="" class="img-fluid w-100">
        </div>
        <div class="content">
          <h2>{{userProfile?.display_name}}</h2>
          <a>{{userProfile?.role}}</a>
      
        </div>
        <ul>
          <li><a class="change-password" (click)="editUser('change-password')" id="change-password">Change Password</a></li>
          <li><a class="my-profile" (click)="editUser('edit-profile')" id="my-profile">My Profile</a></li>
          <li><a class="logout" (click)="logout()" id="log-out">Logout</a></li>
        </ul>
      </div>
    }
  </div>
</header>
@if (openUserEdit) {
  <app-edit-profile [editType]="editType" [userDetails]="editType=='edit-profile'?userDetails:''" (closeClicked)="editClosed()" @slideInParent></app-edit-profile>
}
