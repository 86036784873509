@if (show) {
  <div
    class="modal confirm-modal fade show d-block"
    id="confirm-modal-warning"
    tabindex="-1"
    aria-labelledby="confirmation-modal"
    aria-modal="true"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
        <div class="modal-body">
          <div class="confirm-modal-body">
            <img src="./assets/images/icons/delete-icon.svg" alt="confirm modal Information icon" />
            @if(new_title){
            <h2 id="confirmation-modal">{{ text }}</h2>
            }@else {
              <h2 id="confirmation-modal">Remove {{ title }} Item(s)</h2>
            }
            <form [formGroup]="form">
              <div class="form-group">
                @if(new_title){
                  <label class="form-label mandatory" for="removalReason">{{title}}</label>
                }@else {
                  <label class="form-label mandatory" for="removalReason">Reason for removal</label>
                }
                <textarea class="form-control textarea" formControlName="reason" id="removalReason"></textarea>
                <!-- Display error message if the field is required and empty -->
                @if (form.get("reason")?.invalid && form.get("reason")?.touched) {
                  <div class="errorMessage">Field is required</div>
                }
                @if (!yes_no_btn) {
                  <div class="confirm-buttons">
                    <button class="btn btn-primary w-100" (click)="submit()">Submit</button>
                  </div>
                }@else {
                  <div class="confirm-buttons">
                    <button class="btn btn-outline-primary w-100 me-3"  (click)="close()" id="confirm-no-btn">No</button>
                    <button class="btn btn-primary w-100" (click)="submit()" id="confirm-yes-btn">Yes</button>
                  </div>
                  
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
}
