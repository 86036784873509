import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpClient,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, EmptyError, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { ToasterService } from '../services/toaster.service';
import { ApiConstant } from '../constants/api-constants';
import { StorageService } from '../services/storage.service';

@Injectable()
export class MainInterceptor implements HttpInterceptor {
  private access_token!: string;
  private refresh_token!: string;
  private user_id!: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasterService: ToasterService,
    private apiService: ApiService,
    private storageService: StorageService,
  ) {
    this.readTokens();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isWhiteListed = this.apiService.isTokenIgnore(request.url);
    this.readTokens();
    if (isWhiteListed) {
      return next.handle(request);
    } else {
      if (!this.refresh_token) {
        this.clearUserSessionCookies();
        this.router.navigateByUrl('/login');
        this.toasterService.alert.toast({
          message: 'Session Expired',
          type: 'error',
        });
        return throwError(() => new Error(EmptyError as unknown as string));
      }
      return next.handle(this.addHeaderData(request)).pipe(
        mergeMap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Check for the specific condition in the API response
            if (event?.body?.status_code === 401) {
              // Call the refresh token API
              return this.reLoginWithRefreshToken().pipe(
                mergeMap((resData: any) => {
                  if (resData.success) {
                    console.log('Refresh token login => successful ', resData);
                    this.storageService.set('access_token', resData?.access_token);
                    this.storageService.set('refresh_token', resData?.refresh_token);
                    this.readTokens();
                    return next.handle(this.addHeaderData(request)).pipe(
                      catchError((error: HttpErrorResponse) => {
                        if (error.error === 'login_required') {
                          return next.handle(request);
                        }
                        this.toasterService.alert.toast({
                          message: 'Something bad happened; please try again later',
                          type: 'error',
                        });
                        return throwError(() => 'Something bad happened; please try again later');
                      }),
                    );
                  } else {
                    this.storageService.remove('profile');
                    this.clearUserSessionCookies();
                    this.router.navigateByUrl('/login');
                    this.toasterService.alert.toast({
                      message: 'Something bad happened; please try again later',
                      type: 'error',
                    });
                    return throwError(() => 'Something bad happened; please try again later');
                  }
                }),
              );
            }
          }
          return of(event);
        }),
        catchError((error) => {
          console.log('error response ', error);
          let errorMessage = {
            message: 'Something bad happened; please try again later',
            type: 'error',
          }
           
          if(error?.error?.error?.length < 70){
            errorMessage.message = error?.error?.error;
          }
          if (error.status === 401) {
            if (error?.error?.error === 'Invalid refresh token') {
              this.storageService.remove('profile');
              this.clearUserSessionCookies();
              this.router.navigateByUrl('/login');
              this.toasterService.alert.toast(errorMessage);
              return throwError(() => errorMessage);
            } else if (error?.error?.error === 'Invalid previous password') {
              return throwError(() => error?.error);
            } else {
              return this.reLoginWithRefreshToken().pipe(
                mergeMap((resData: any) => {
                  if (resData.success) {
                    console.log('Refresh token login => successful ', resData);
                    this.storageService.set('access_token', resData?.access_token);
                    this.storageService.set('refresh_token', resData?.refresh_token);
                    this.readTokens();
                    return next.handle(this.addHeaderData(request)).pipe(
                      catchError((error: HttpErrorResponse) => {
                        if (error.error === 'login_required') {
                          return next.handle(request);
                        }
                        this.toasterService.alert.toast(errorMessage);
                        return throwError(() => errorMessage);
                      }),
                    );
                  } else {
                    this.storageService.remove('profile');
                    this.clearUserSessionCookies();
                    this.router.navigateByUrl('/login');
                    this.toasterService.alert.toast(errorMessage);
                    return throwError(() => errorMessage);
                  }
                }),
              );
            }
          }
          if (
            error.status === 500 &&
            error?.error?.error?.includes('Attempt limit exceeded, please try after some time.')
          ) {
            const returnError = {
              error: 'Attempt limit exceeded, please try after some time.',
              type: 'error',
            };
            return throwError(() => returnError);
          }
          if (error.status === 403) {
            this.storageService.remove('profile');
            this.clearUserSessionCookies();
            this.router.navigateByUrl('/login');
            this.toasterService.alert.toast(errorMessage);
            return throwError(() => errorMessage);
          }
          if (error.status === 404) {
            this.router.navigateByUrl('/access-denied');
            return throwError(() => errorMessage);
          } else {
            this.toasterService.alert.toast(errorMessage);
            return throwError(() => errorMessage);
          }
        }),
      );
    }
  }
  private reLoginWithRefreshToken(): Observable<any> {
    this.readTokens();
    const payload = {
      refresh_token: this.refresh_token,
      user_id: this.user_id,
    };
    const endpoint = this.apiService.getApiUrl(ApiConstant.AUTHENTICATION.GET_REFRESH_TOKEN);
    return this.http.post(endpoint, payload);
  }

  private addHeaderData(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.access_token}`,
      },
    });
  }

  private clearUserSessionCookies() {
    this.readTokens();
  }

  private readTokens() {
    this.access_token = this.storageService.get('access_token') || '{}';
    this.refresh_token = this.storageService.get('refresh_token') || '{}';
    this.user_id = this.storageService.get('profile')?.aws_user_id || null;
  }
}
