import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withPreloading } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MainInterceptor } from './core/interceptor/main.interceptor';
import { CustomPreloadingStrategy } from './custom-preloading-strategy';

const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true }];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(HttpClientModule),
    httpInterceptorProviders,
    provideAnimations(),
  ],
};

