import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { ToasterComponent } from './core/components/toaster/toaster.component';
import { ConfirmRemoveComponent } from './core/components/confirm-remove/confirm-remove.component';
import { LoaderComponent } from './core/components/loader/loader.component';
import { ConfirmationBoxComponent } from './core/components/confirmation-box/confirmation-box.component';
import { LoaderService } from './core/services/loader.service';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, ToasterComponent, ConfirmRemoveComponent, LoaderComponent, ConfirmationBoxComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent  implements OnInit{
  title = 'af-lims';
  constructor(
    private readonly router: Router,
    private readonly loaderService: LoaderService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loaderService.displayLoader(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loaderService.displayLoader(false);
      }
    });
  }

  ngOnInit() {

  }
}
