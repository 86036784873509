import { Routes } from '@angular/router';
import { LoginComponent } from './feature/account/login/login.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { PlasmidsComponent } from './feature/inventory/biological-sample/plasmids/plasmids.component';
import { BacterialStrainsComponent } from './feature/inventory/biological-sample/bacterial-strains/bacterial-strains.component';
import { CellLinesComponent } from './feature/inventory/biological-sample/cell-lines/cell-lines.component';
import { FreezerComponent } from './feature/inventory/lab-equipments/freezer/freezer.component';
import { RefrigeratorComponent } from './feature/inventory/lab-equipments/refrigerator/refrigerator.component';
import { authGuard, loginGuard, permissionGuard } from './core/services/auth.service';
import { ShelvesComponent } from './feature/inventory/lab-equipments/shelves/shelves.component';
import { StorageCabinetsComponent } from './feature/inventory/lab-equipments/storage-cabinets/storage-cabinets.component';
import { AssayKitsComponent } from './feature/inventory/lab-consumables/assay-kits/assay-kits.component';
import { GlassWaresComponent } from './feature/inventory/lab-consumables/lab-wares/glass-wares/glass-wares.component';
import { PlasticWaresComponent } from './feature/inventory/lab-consumables/lab-wares/plastic-wares/plastic-wares.component';
import { InstrumentsComponent } from './feature/inventory/instruments/instruments/instruments.component';
import { CcmAAComponent } from './feature/inventory/lab-consumables/cell-culture-reagents/ccm-a-a/ccm-a-a.component';
import { GBLrComponent } from './feature/inventory/lab-consumables/cell-culture-reagents/g-b-lr/g-b-lr.component';
import { ApiService } from './core/services/api.service';
import { inject } from '@angular/core';
import { ApiConstant } from './core/constants/api-constants';
import { environment } from '../environments/environment';
import { ProjectComponent } from './feature/project/project/project.component';
import { InventoryComponent } from './feature/inventory/inventory/inventory.component';
import { ProtocolsComponent } from './feature/protocols/protocols/protocols.component';
import { WorkFlowComponent } from './feature/project/project/work-flow/work-flow.component';
import { ProjectListingComponent } from './feature/project/project/project-listing/project-listing.component';
import { VendorsComponent } from './feature/vendors/vendors/vendors.component';
import { ProductComponent } from './feature/product/product.component';
import { AntibodyProjectWorkflowComponent } from './feature/project/project/antibody-project-workflow/antibody-project-workflow.component';
import { MammalianCellProjectWorkflowComponent } from './feature/project/project/mammalian-cell-project-workflow/mammalian-cell-project-workflow.component';
import { SettingsComponent } from './feature/settings/settings/settings.component';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { ForgotPasswordComponent } from './feature/account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './feature/account/reset-password/reset-password.component';
import { AccountActivationComponent } from './feature/account/account-activation/account-activation.component';
import { editorGuard } from './feature/json-editor/json-editor.service';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    resolve: {
      menu: () => inject(ApiService).ExecuteGet(environment.apiEndPoint + '/' + ApiConstant.AUTHENTICATION.MENU),
    },
    children: [
      {
        path: 'inventory',
        loadComponent : ()=> import('./feature/inventory/inventory/inventory.component').then(comp=>comp.InventoryComponent),
        children: [
          {
            path: 'biological-sample-inventory/plasmids',
            loadComponent : ()=> import('./feature/inventory/biological-sample/plasmids/plasmids.component').then(comp=>comp.PlasmidsComponent),
            data: { preload: "biological-sample-inventory" }
          },
          {
            path: 'biological-sample-inventory/bacterial-strains',
            loadComponent : ()=> import('./feature/inventory/biological-sample/bacterial-strains/bacterial-strains.component').then(comp=>comp.BacterialStrainsComponent),
            data: { preload: "biological-sample-inventory" }
          },
          {
            path: 'biological-sample-inventory/cell-lines',
            loadComponent : ()=> import('./feature/inventory/biological-sample/cell-lines/cell-lines.component').then(comp=>comp.CellLinesComponent),
            data: { preload: "biological-sample-inventory" }
          },
          {
            path: 'lab-equipments/refrigerator',
            loadComponent : ()=> import('./feature/inventory/lab-equipments/refrigerator/refrigerator.component').then(comp=>comp.RefrigeratorComponent),
            data: { preload: "lab-equipment" }
          },
          {
            path: 'lab-equipments/freezer',
            loadComponent : ()=> import('./feature/inventory/lab-equipments/freezer/freezer.component').then(comp=>comp.FreezerComponent),
            data: { preload: "lab-equipment" }
          },
          {
            path: 'lab-equipments/storage-cabinet',
            loadComponent : ()=> import('./feature/inventory/lab-equipments/storage-cabinets/storage-cabinets.component').then(comp=>comp.StorageCabinetsComponent),
            data: { preload: "lab-equipment" }
          },
          {
            path: 'lab-equipments/shelves',
            loadComponent : ()=> import('./feature/inventory/lab-equipments/shelves/shelves.component').then(comp=>comp.ShelvesComponent),
            data: { preload: "lab-equipment" }
          },
          {
            path: 'instruments',
            loadComponent : ()=> import('./feature/inventory/instruments/instruments/instruments.component').then(comp=>comp.InstrumentsComponent),
            data: { preload: "instruments" }
          },
          {
            path: 'lab-consumables/assay-kits',
            loadComponent : ()=> import('./feature/inventory/lab-consumables/assay-kits/assay-kits.component').then(comp=>comp.AssayKitsComponent),
            data: { preload: "lab-consumables/assay-kits" }
          },
          {
            path: 'lab-consumables/cell-culture-reagents/cell-culture-media-antibiotics-agar-plates',
            loadComponent : ()=> import('./feature/inventory/lab-consumables/cell-culture-reagents/ccm-a-a/ccm-a-a.component').then(comp=>comp.CcmAAComponent),
            data: { preload: "lab-consumables/cell-culture-reagents" }
          },
          {
            path: 'lab-consumables/cell-culture-reagents/gels-buffers-lab-reagents',
            loadComponent : ()=> import('./feature/inventory/lab-consumables/cell-culture-reagents/g-b-lr/g-b-lr.component').then(comp=>comp.GBLrComponent),
            data: { preload: "lab-consumables/cell-culture-reagents" }
          },
          {
            path: 'lab-consumables/labwares/glasswares',
            loadComponent : ()=> import('./feature/inventory/lab-consumables/lab-wares/glass-wares/glass-wares.component').then(comp=>comp.GlassWaresComponent),
            data: { preload: "lab-consumables/labwares" }
          },
          {
            path: 'lab-consumables/labwares/plasticwares',
            loadComponent : ()=> import('./feature/inventory/lab-consumables/lab-wares/plastic-wares/plastic-wares.component').then(comp=>comp.PlasticWaresComponent),
            data: { preload: "lab-consumables/labwares" }
          },
          {
            path: '',
            redirectTo: 'biological-sample-inventory/plasmids',
            pathMatch: 'full',
          },
        ],
        canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'projects',
        loadComponent : ()=> import('./feature/project/project/project.component').then(comp=>comp.ProjectComponent),
        children: [
          {
            path: '',
            loadComponent : ()=> import('./feature/project/project/project-dashboard/project-dashboard.component').then(comp=>comp.ProjectDashboardComponent),
            // component: ProjectListingComponent,
          },
          {
            path: 'bsp-work-flow/:id/:exp_id',
            loadComponent : ()=> import('./feature/project/project/work-flow/work-flow.component').then(comp=>comp.WorkFlowComponent),
            // component: WorkFlowComponent,
          },
          {
            path: 'pep-work-flow/:id/:exp_id',
            loadComponent : ()=> import('./feature/project/project/work-flow/work-flow.component').then(comp=>comp.WorkFlowComponent),
            // component: WorkFlowComponent,
          },
          {
            path: 'app-work-flow/:id/:exp_id',
            loadComponent : ()=> import('./feature/project/project/antibody-project-workflow/antibody-project-workflow.component').then(comp=>comp.AntibodyProjectWorkflowComponent),
            // component: AntibodyProjectWorkflowComponent,
          },
          {
            path: 'msp-work-flow/:id/:exp_id',
            loadComponent : ()=> import('./feature/project/project/mammalian-cell-project-workflow/mammalian-cell-project-workflow.component').then(comp=>comp.MammalianCellProjectWorkflowComponent),
            // component: MammalianCellProjectWorkflowComponent,
          },

          // simsco exp workflow
          {
            path: 'ssDNA-to-dsDNA-work-flow/:project_id/:instance_id/:workflow_id',
            loadComponent : ()=> import('./feature/project/experiment-workflow/purified-ssdna-to-dsdna-workflow/purified-ssdna-to-dsdna-workflow.component').then(comp=>comp.PurifiedSsdnaToDsdnaWorkflowComponent),
            // component: AntibodyProjectWorkflowComponent,
          },
          {
            path: 'electocompetent-strains-workflow/:project_id/:instance_id/:workflow_id',
            loadComponent : ()=> import('./feature/project/experiment-workflow/electocompetent-strains-workflow/electocompetent-strains-workflow.component').then(comp=>comp.ElectocompetentStrainsWorkflowComponent),
            // component: AntibodyProjectWorkflowComponent,
          },
          {
            path: 'sep-workflow/:project_id/:instance_id/:workflow_id',
            loadComponent : ()=> import('./feature/project/experiment-workflow/sep-workflow/sep-workflow.component').then(comp=>comp.SepWorkflowComponent),
          },
          {
            path: 'antigen-spec-antibody-workflow/:project_id/:instance_id/:workflow_id',
            loadComponent : ()=> import('./feature/project/experiment-workflow/antigen-spec-antibody-workflow/antigen-spec-antibody-workflow.component').then(comp=>comp.AntigenSpecAntibodyWorkflowComponent),
          },
          {
            path: 'apc-elisa-workflow/:project_id/:instance_id/:workflow_id',
            loadComponent : ()=> import('./feature/project/experiment-workflow/apc-elisa-workflow/apc-elisa-workflow.component').then(comp=>comp.ApcElisaWorkflowComponent),
          },
          {
            path: 'dna-sequencing-workflow/:project_id/:instance_id/:workflow_id',
            loadComponent : ()=> import('./feature/project/experiment-workflow/dna-sequencing-workflow/dna-sequencing-workflow.component').then(comp=>comp.DnaSequencingWorkflowComponent),
          }
        ],
        canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'protocols',
        loadComponent : ()=> import('./feature/protocols/protocols/protocols.component').then(comp=>comp.ProtocolsComponent),
        data: { preload: "protocols" },
        canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'products',
        loadComponent : ()=> import('./feature/product/product.component').then(comp=>comp.ProductComponent),
        data: { preload: "products" },
        canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'vendors',
        loadComponent : ()=> import('./feature/vendors/vendors/vendors.component').then(comp=>comp.VendorsComponent),
        data: { preload: "vendors" },
        canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'settings/user',
        loadComponent : ()=> import('./feature/settings/settings/settings.component').then(comp=>comp.SettingsComponent),
        data: { preload: "settings/user" },
        canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'json-editor',
        loadComponent : ()=> import('./feature/json-editor/json-editor/json-editor.component').then(comp=>comp.JsonEditorComponent),
        // component: SettingsComponent,
        canActivate: [authGuard,editorGuard],
      },
      {
        path: 'access-denied',
        loadComponent : ()=> import('./shared/components/access-denied/access-denied.component').then(comp=>comp.AccessDeniedComponent),
        canActivate: [authGuard],
      },
      {
        path: 'study-list',
        loadComponent : ()=> import('./feature/inventory/study-list/study-list.component').then(comp=>comp.StudyListComponent),
        // component: ProtocolsComponent,
        // canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'lib-prj-listing',
        loadComponent : ()=> import('./feature/project/lib-temp-exp-listing/lib-temp-exp-listing.component').then(comp=>comp.LibTempExpListingComponent),
        // component: ProtocolsComponent,
        // canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'disease-program',
        loadComponent : ()=> import('./feature/disease-program/disease-program.component').then(comp=>comp.DiseaseProgramComponent),
        // component: ProtocolsComponent,
        // canActivate: [authGuard, permissionGuard],
      },
      {
        path: 'experiments',
        loadComponent : ()=> import('./feature/experiments/experiments/experiments.component').then(comp=>comp.ExperimentsComponent),
      },
      {
        path: 'manus-bio-experiments',
        loadComponent : ()=> import('./feature/manus-bio/experiments/experiments.component').then(comp=>comp.ExperimentsComponent),
      },
      {
        path: '',
        redirectTo: 'inventory',
        pathMatch: 'full',
      },
    ],
    canActivate: [authGuard],
  },
  {
    path: 'login',
    loadComponent : ()=> import('./feature/account/login/login.component').then(comp=>comp.LoginComponent),
    data: { preload: "login" },
    canActivate: [loginGuard],
  },
  {
    path: 'forgot-password',
    loadComponent : ()=> import('./feature/account/forgot-password/forgot-password.component').then(comp=>comp.ForgotPasswordComponent),
    data: { preload: "forgot-password" },
    canActivate: [loginGuard],
  },
  {
    path: 'reset-password/:token',
    loadComponent : ()=> import('./feature/account/reset-password/reset-password.component').then(comp=>comp.ResetPasswordComponent),
    data: { preload: "reset-password" },
    canActivate: [loginGuard],
  },
  {
    path: 'activate-account/:token',
    loadComponent : ()=> import('./feature/account/account-activation/account-activation.component').then(comp=>comp.AccountActivationComponent),
    data: { preload: "activate-account" },
    canActivate: [loginGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
    data: { preload: "login" },
    pathMatch: 'full',
  },
];
