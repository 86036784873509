import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  // Method to set a secure cookie
  setClientIdCookie(clientId: string) {
    const expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + 60);  // cookie expiration (1 hour)

    document.cookie = `client-id=${clientId}; expires=${expirationDate.toUTCString()}; path=/; Secure; HttpOnly; SameSite=Strict`;
  }

  // Method to get the client ID from the cookie
  getClientIdCookie(): string | null {
    const name = 'client-id=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArr = decodedCookie.split(';');

    for (const element of cookieArr) {
      let c = element.trim();
      if (c.startsWith(name)) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
}
