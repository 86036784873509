<div 
  class="modal common-right-modal show d-block"
  id="inventorymasterModal"
  tabindex="-1"
  aria-labelledby="addMasterModal"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog" @slideIn>
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex align-items-center">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
          <h5 class="modal-title" id="addMasterModal" role="heading" aria-level="2">{{ title }}</h5>
        </div>
          <div class="btn-wrap">
          @if(editType==='edit-profile' && !isEdit){
          <button (click)="isEdit=true;" class="btn btn-primary">Edit</button>
          }
          @if(isEdit){
            @if(editType==='edit-profile'){
              <button type="button" class="btn btn-outline-primary " (click)="isEdit=false;editType='edit-profile'" id="edit-profile-cancel-btn">Cancel</button>
              <button type="button" class="btn btn-primary " (click)="submit()" id="edit-profile-submit">Submit</button>
            }
            @else{
              <button type="button" class="btn btn-primary w-100" (click)="submit()" id="edit-profile-submit-btn">Submit</button>
            }
          }
        </div>
      </div>

      <div class="modal-body">
        @if(editType==='edit-profile' && !isEdit){
         
          <div class="view-wrapper">
            <h2 class="add-heading">
              <span>Profile Information</span>
            </h2>
          <div class="row">
          <div class="col-md-6">
            <label class="labeltext">First Name </label>
            <div class="controlvalue">{{userDetails?.first_name}}</div>
          </div>
          <div class="col-md-6">
            <label class="labeltext">Last Name </label>
            <div class="controlvalue">{{userDetails?.last_name}}</div>
          </div>
          <div class="col-md-6">
            <label class="labeltext">Email</label>
            <div class="controlvalue">{{userDetails?.email}}</div>
          </div>
          <div class="col-md-6">
            <label class="labeltext">Role </label>
            <div class="controlvalue">{{userDetails.role?.value}}</div>
          </div>
          <div class="col-md-6">
            <label class="labeltext">Status  </label>
            <div class="controlvalue">{{userDetails.status?.value}}</div>
          </div>
        </div>
        </div>
        }
        @if(isEdit){
          <div class="view-wrapper">
            @if(editType==='edit-profile'){
              <h2 class="add-heading">
                <span>Edit Profile</span>
              </h2>
            }
          <ngx-dynamicforms
            #lib
            [fields]="generetedJSON"
            (errorEndPoint)="IdEmitterFun($event)"
            (returnForm)="geneartedForm($event)"
          ></ngx-dynamicforms>
          @if(editType==='edit-profile'){
              <div class="row">
                <div class="col-md-6">
                  <label class="labeltext">Email</label>
                  <div class="controlvalue">{{userDetails?.email}}</div>
                </div>
                <div class="col-md-6">
                  <label class="labeltext">Role </label>
                  <div class="controlvalue">{{userDetails.role?.value}}</div>
                </div>
              </div>
            
          }
          </div>
        }
      </div>
    </div>
  </div>
</div>
