import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToasterService } from '../../services/toaster.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';


@Component({
  selector: 'app-confirm-remove',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './confirm-remove.component.html',
  styleUrl: './confirm-remove.component.scss',
})
export class ConfirmRemoveComponent implements OnInit {
  // Create the form group
  form: FormGroup;
  constructor(
    private toasterService: ToasterService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      reason: ['', Validators.required], //  form control with required validator
    });
  }

  @Output() eventEmitter = new EventEmitter();
  show: boolean = false;
  title!: string;
  text!:string;
  resolve: any;
  yes_no_btn!:boolean;
  new_title!:string
  statusSubscription:any
  ngOnInit(): void {
   this.statusSubscription= this.toasterService.confirmDeleteTigger$.subscribe((val) => {
      this.resolve = val.resolve;
      this.title = val.title;
      this.text=val.text;
      this.yes_no_btn=val.yes_no_btn;
      this.new_title=val.new_title
      if (val) {
        this.show = true;
      }
      document.body.classList.add('modal-open');
    });
  }

  close() {
    setTimeout(() => {
      this.show = false;
      this.form.reset();
    }, 500);
    document.body.classList.remove('modal-open');
  }

  // Handle form submission
  submit() {
    if (this.form.valid) {
      this.resolve(this.form.value.reason);
      this.close();
    } else {
      // Mark all form controls as touched to display error messages
      this.form.markAllAsTouched();
    }
  }
  public ngOnDestroy(): void {
this.statusSubscription.unsubscribe()
  }
}
